import * as React from 'react';

import { useLocation } from 'js/lib/useRouter';

import { getCopy } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import { CourseraMetatags } from 'bundles/seo';
import type { CourseraMetatagsProps } from 'bundles/seo/types';

import _t from 'i18n!nls/coursera-plus';

const CourseraPlusHubPageMetatags: React.FunctionComponent<CourseraMetatagsProps> = (props) => {
  const location = useLocation();
  const isCourseraLiteLandingPage = location.pathname.includes('/courseralite');
  const { numberOfProducts, numberOfProductsInCourseraLite } = getCopy();
  let metaTitle = _t('Coursera Plus | Unlimited Access to #{numberOfProducts} Online Courses', {
    numberOfProducts,
  });
  let metaDescription = _t(
    'Invest in your professional goals with Coursera Plus. Get Unlimited access to over 90% of courses, Projects, Specializations, and Professional Certificates.'
  );

  if (isCourseraLiteLandingPage) {
    metaTitle = _t('Coursera Lite | Get access to #{numberOfProducts} Online Courses', {
      numberOfProducts: numberOfProductsInCourseraLite,
    });
    metaDescription = _t(
      'Invest in your professional goals with Coursera Lite. Get access to over 90% of courses, Specializations, and Professional Certificates.'
    );
  }

  return (
    <CourseraMetatags
      metaNameAndProperties={{
        title: metaTitle,
        description: metaDescription,
        disableCourseraSuffix: true,
        disableCrawlerIndexing: props.disableCrawlerIndexing,
      }}
    />
  );
};

export default CourseraPlusHubPageMetatags;

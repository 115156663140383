import * as React from 'react';
import { IndexRoute, Route } from 'react-router';

import { Route as CourseraRoute } from '@coursera/react-router';

import loadOnRoute from 'bundles/common/components/loadOnRoute';
import CourseraPlusHubApp from 'bundles/coursera-plus/CourseraPlusHubApp';
import NotFound from 'bundles/phoenix/components/NotFound';

const LandingPage = loadOnRoute(() => import('bundles/coursera-plus/components/LandingPage'));
const MarketingHome = loadOnRoute(() => import('bundles/coursera-plus/components/MarketingHome'));

export default (
  <CourseraRoute
    component={CourseraPlusHubApp}
    path="/"
    handle={{
      tracking: { data: () => ({ page: { type: 'cplus_description' } }) },
    }}
  >
    {/* Coursera Lite Route */}
    <Route getComponent={MarketingHome} path="courseralite/special/:slug" />

    {/* Coursera Plus Routes */}
    <Route path="courseraplus">
      <IndexRoute getComponent={LandingPage} />
      <Route getComponent={LandingPage} path="subscribe/:id" />
      <Route getComponent={MarketingHome} path="special/:slug" />
    </Route>

    <Route component={NotFound} path="*" />
  </CourseraRoute>
);

import { useApolloClient, useQuery } from '@apollo/client';

import config from 'js/app/config';
import store from 'js/lib/coursera.store';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';
import user from 'js/lib/user';

import { getSavingProductType } from 'bundles/browse/components/utils/BookmarkUtils';
import type {
  GetBundleProductsQuery as GetBundleProductsQueryData,
  GetBundleProductsQueryVariables,
} from 'bundles/coursera-plus/courseraPlusCollections/__generated__/GetBundleProductsQuery';
import { GetBundleProductsQuery } from 'bundles/coursera-plus/courseraPlusCollections/bundleQueries';
import courseraPlusEpicClient from 'bundles/epic/clients/courseraPlus';
import {
  COURSE,
  PROFESSIONAL_CERTIFICATE,
  SPECIALIZATION_ENTITY_TYPE,
} from 'bundles/search-common/constants/entityTypes';
import { SaveProductQuery } from 'bundles/unified-common/utils/restQueries';

import _t from 'i18n!nls/coursera-plus';

// For the initial Q4 2023 MVP launch of "Bundles & Coursera Plus", we are hard-coding
// bundle info directly into the FE and not building it out properly in the BE.
// The bundle `id` will be passed to the BE in the courseraPlusSubscriptions.v1#createCart
// request so that the BE knows to send a bundle specific email template,
// and the bundle `products` will be saved as a post-purchase side effect on the FE
export const COMPUTER_SCIENCE_BUNDLE = {
  id: 'computer-science-collection',
  // This a proper branded term that should not be translated
  name: 'Computer Science Collection',
  skillName: () => _t('computer science'),
  products: [
    // Introduction to Python Programming
    { productType: COURSE, productId: 'i_7JYlNJEeq6NwpG-k58_Q' },
    // Meta Front-End Developer
    { productType: PROFESSIONAL_CERTIFICATE, productId: 'KPSzVL9AEeyfhAreTccY9Q' },
    // Meta Back-End Developer
    { productType: PROFESSIONAL_CERTIFICATE, productId: 'child~O9lcQYAhEe2ifgpUSi87bw' },
    // Microsoft Cybersecurity Analyst
    { productType: PROFESSIONAL_CERTIFICATE, productId: 's-9jsN74QTylrBFE7FCJoQ' },
    // Introduction to Machine Learning
    { productType: COURSE, productId: '8BJHzZD_EeiKohJBs5wRGA' },
  ],
};

// This map will be used at checkout to retrieve bundle info for the `bundleId` tied to a cart
// Additional bundles will be added in Q1 2024
export const BUNDLE_MAP = {
  [COMPUTER_SCIENCE_BUNDLE.id]: COMPUTER_SCIENCE_BUNDLE,
};

export const getIsValidBundleId = (bundleId: string) => {
  return !!BUNDLE_MAP[bundleId]; // Returns true if the bundleId exists in the BUNDLE_MAP, false otherwise
};

export const courseraPlusBundleExploreLink = `${config.url.base}my-learning?myLearningTab=SAVED`;

export const useSaveProductsForCourseraPlusBundle = () => {
  const ARBITRARY_TIMEOUT = 250;
  const client = useApolloClient();

  const saveProductsForCourseraPlusBundle = async (bundleId: string): Promise<undefined> => {
    // Use `reverse()` because the Saved tab shows in order of most recently saved,
    // so we need to call the Save API for the products in reverse order to keep the first
    // product in the array showing up first on the Saved tab
    const products = BUNDLE_MAP[bundleId].products.reverse();

    // Call API sequentially
    for (const product of products) {
      const { productType, productId } = product;
      // eslint-disable-next-line no-await-in-loop
      await client.query({
        query: SaveProductQuery,
        variables: {
          input: {
            product: {
              // eslint-disable-next-line camelcase
              product_id: productId,
              // eslint-disable-next-line camelcase
              product_type: getSavingProductType(productType),
            },
          },
        },
        errorPolicy: 'all',
      });

      // I observed that even waiting for the API to finish is not enough to guanrantee the order
      // Adding an arbitrary wait before making the next request
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => {
        setTimeout(resolve, ARBITRARY_TIMEOUT);
      });
    }

    return Promise.resolve(undefined);
  };

  return {
    saveProductsForCourseraPlusBundle,
  };
};

export const useFetchBundleProducts = (bundleId: string) => {
  const productIds = BUNDLE_MAP[bundleId].products.map((product) => {
    if (product.productType === PROFESSIONAL_CERTIFICATE) {
      return tupleToStringKey([SPECIALIZATION_ENTITY_TYPE, product.productId]);
    }
    return tupleToStringKey([COURSE, product.productId]);
  });

  const { data } = useQuery<GetBundleProductsQueryData, GetBundleProductsQueryVariables>(GetBundleProductsQuery, {
    variables: {
      ids: productIds,
    },
  });
  return data?.XdpV1Resource.multiGet?.elements;
};

const HAS_PURCHASED_COMPUTER_SCIENCE_COLLECTION_KEY = 'has-purchased-computer-science-collection';

export const setHasUserPurchasedCollection = () => {
  const storeKey = HAS_PURCHASED_COMPUTER_SCIENCE_COLLECTION_KEY;
  const users = store.get(storeKey) ?? [];
  if (!users.includes(user.get().id)) {
    users.push(user.get().id);
    store.set(storeKey, users);
  }
};

export const getHasUserPurchasedCollection = () => {
  const users = store.get(HAS_PURCHASED_COMPUTER_SCIENCE_COLLECTION_KEY);
  return users ? users.includes(user.get().id) : false;
};

export const removeHasUserPurchasedCollection = () => {
  const storeKey = HAS_PURCHASED_COMPUTER_SCIENCE_COLLECTION_KEY;
  let users = store.get(storeKey);
  if (users) {
    users = users.filter((userId: number) => Number(userId) !== Number(user.get().id));
    store.set(storeKey, users);
  }
};

export const recordImpressionForCollectionPagePendoEnabled = () => {
  return courseraPlusEpicClient.get('isCollectionPagePendoEnabled');
};

export const previewCollectionPagePendoEnabled = () => {
  return courseraPlusEpicClient.preview('isCollectionPagePendoEnabled');
};

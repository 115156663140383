import gql from 'graphql-tag';

export const GetBundleProductsQuery = gql`
  query GetBundleProductsQuery($ids: [String!]!) {
    XdpV1Resource {
      multiGet(ids: $ids) {
        elements {
          id
          productType
          xdpMetadata {
            __typename
            ... on XdpV1_sdpMetadataMember {
              sdpMetadata {
                id
                name
                slug
                level
                productVariant
                certificateLogo
                totalEnrollmentCount
                ratings {
                  ratingCount
                  averageFiveStarRating
                }
                partners {
                  id
                  squareLogo
                  name
                }
              }
            }
            ... on XdpV1_cdpMetadataMember {
              cdpMetadata {
                id
                name
                slug
                level
                photoUrl
                totalEnrollmentCount
                ratings {
                  ratingCount
                  averageFiveStarRating
                }
                skills
                partners {
                  id
                  shortName
                  name
                  squareLogo
                }
              }
            }
          }
        }
      }
    }
  }
`;
